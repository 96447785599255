import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;

  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
   <svg xmlns="http://www.w3.org/2000/svg"   width="100%" height="100%"  viewBox="0 0 1080.000000 1080.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g 
fill={PRIMARY_MAIN}  stroke="none" strokeWidth="1"  fillRule="evenodd"  transform="translate(0.000000,1080.000000) scale(0.100000,-0.100000)">


<path d="M5135 8175 c-209 -25 -427 -82 -616 -162 -109 -47 -289 -139 -289
-149 0 -2 525 -4 1168 -4 1035 1 1165 2 1147 16 -36 27 -209 112 -314 154
-343 136 -731 188 -1096 145z"/>
<path d="M3571 7265 c-217 -293 -356 -630 -417 -1010 -19 -114 -27 -525 -11
-541 4 -4 46 13 95 38 48 25 301 157 562 293 261 136 628 327 815 425 640 334
666 348 1307 680 197 102 358 188 358 193 0 4 -595 7 -1323 7 l-1323 0 -63
-85z"/>
<path d="M7151 7000 c-195 -101 -389 -202 -691 -360 -113 -59 -266 -139 -340
-177 -74 -39 -283 -148 -465 -243 -181 -95 -384 -200 -450 -235 -567 -295
-705 -368 -705 -371 0 -2 706 -4 1570 -4 l1570 0 7 28 c5 15 8 142 8 282 -1
312 -24 468 -110 725 -51 153 -185 434 -211 442 -5 1 -87 -38 -183 -87z"/>
<path d="M3290 5086 c0 -29 150 -315 218 -416 102 -152 184 -250 315 -376 265
-257 555 -429 907 -537 237 -73 400 -97 664 -97 347 0 618 56 925 193 485 215
882 598 1111 1072 37 77 70 148 73 158 6 16 -21 17 -486 17 l-492 0 -48 -94
c-206 -405 -622 -659 -1077 -658 -469 0 -876 250 -1090 670 l-42 82 -489 0
c-411 0 -489 -2 -489 -14z"/>
<path d="M2727 3243 c-4 -3 -7 -98 -7 -210 l0 -203 231 0 230 0 -3 48 -3 47
-157 3 -158 3 0 34 0 35 150 0 150 0 0 45 0 45 -150 0 -150 0 0 30 0 30 155 0
155 0 0 50 0 50 -218 0 c-120 0 -222 -3 -225 -7z"/>
<path d="M4680 3041 l0 -211 160 0 c186 0 249 11 295 51 47 42 58 75 54 173
-5 101 -20 129 -95 166 -46 23 -61 25 -231 28 l-183 4 0 -211z m335 83 c29
-19 42 -72 29 -123 -13 -51 -56 -71 -156 -71 l-78 0 0 110 0 110 91 -5 c58 -4
99 -11 114 -21z"/>
<path d="M6537 3243 c-4 -3 -7 -98 -7 -210 l0 -203 65 0 65 0 0 80 0 80 125 0
125 0 0 -80 0 -80 65 0 65 0 0 210 0 210 -65 0 -65 0 0 -80 0 -81 -122 3 -123
3 -3 78 -3 77 -58 0 c-32 0 -61 -3 -64 -7z"/>
<path d="M7610 3040 l0 -210 65 0 c51 0 65 3 65 15 0 19 -2 19 45 -5 85 -43
224 -15 266 54 27 43 26 140 -1 184 -42 68 -190 96 -273 52 l-37 -20 0 70 0
70 -65 0 -65 0 0 -210z m307 -2 c31 -29 30 -72 -2 -103 -37 -38 -140 -32 -167
9 -16 23 -8 75 14 96 32 29 123 28 155 -2z"/>
<path d="M5760 3185 c0 -43 -1 -45 -30 -45 -28 0 -30 -3 -30 -35 0 -32 2 -35
30 -35 l30 0 0 -85 c0 -136 18 -155 150 -155 l80 0 0 49 0 50 -47 3 -48 3 -3
68 -3 67 51 0 50 0 0 34 c0 34 -1 35 -47 38 l-48 3 -3 43 -3 42 -65 0 -64 0 0
-45z"/>
<path d="M3337 3146 c-52 -14 -81 -37 -92 -73 -6 -22 -4 -23 62 -23 44 1 75 6
87 15 27 20 122 20 129 0 9 -22 -5 -26 -118 -36 -118 -10 -147 -19 -169 -51
-36 -51 -15 -113 51 -147 52 -27 211 -5 235 32 6 8 10 5 14 -10 6 -21 13 -23
68 -23 l61 0 -3 122 c-3 158 -4 162 -49 183 -50 24 -209 30 -276 11z m189
-201 c-7 -29 -56 -55 -105 -55 -44 0 -61 10 -61 36 0 18 7 24 33 29 108 18
132 21 136 19 2 -2 0 -15 -3 -29z"/>
<path d="M5370 3154 c-79 -15 -111 -36 -124 -81 -7 -22 -5 -23 62 -23 50 0 73
4 82 15 15 18 125 21 136 4 13 -21 -17 -32 -113 -40 -130 -11 -149 -16 -173
-46 -40 -51 -18 -122 46 -149 49 -21 150 -17 203 6 52 24 51 24 51 5 0 -12 14
-15 63 -15 l62 0 -3 122 c-3 158 -4 162 -49 183 -37 18 -188 29 -243 19z m160
-199 c0 -29 -33 -53 -83 -62 -56 -9 -87 3 -87 32 0 14 1 25 3 26 1 0 38 6 82
14 44 7 81 13 83 14 1 0 2 -10 2 -24z"/>
<path d="M6146 3146 c-62 -14 -96 -41 -96 -77 0 -17 8 -19 66 -19 45 0 70 5
80 15 22 21 122 21 131 0 3 -9 3 -18 0 -21 -3 -3 -56 -10 -118 -15 -61 -6
-124 -16 -140 -24 -65 -34 -55 -141 15 -170 55 -23 154 -19 209 8 43 22 46 23
50 5 4 -15 15 -18 66 -18 l61 0 0 119 c0 138 -7 164 -51 186 -43 23 -196 29
-273 11z m194 -186 c0 -36 -83 -77 -140 -68 -30 4 -49 33 -32 50 21 21 172 37
172 18z"/>
<path d="M3720 3104 l0 -44 98 0 97 0 -108 -72 -108 -72 3 -40 3 -41 213 -3
212 -2 0 44 0 45 -102 3 -103 3 103 65 103 65 -3 45 -3 45 -202 2 -203 2 0
-45z"/>
<path d="M4160 3140 c0 -6 34 -72 76 -146 41 -74 74 -142 72 -152 -2 -13 -15
-18 -56 -20 l-52 -3 0 -50 0 -49 75 0 c78 0 116 12 147 47 18 19 208 360 208
373 0 3 -32 6 -71 6 l-71 -1 -48 -95 -48 -95 -40 83 c-23 46 -42 88 -44 94 -6
16 -148 23 -148 8z"/>
<path d="M7110 3028 c0 -128 8 -152 60 -182 50 -30 162 -29 213 2 36 21 37 21
37 2 0 -18 7 -20 65 -20 l65 0 0 155 0 155 -65 0 -65 0 0 -85 c0 -71 -3 -88
-19 -104 -23 -23 -79 -36 -110 -26 -44 14 -51 32 -51 126 l0 89 -65 0 -65 0 0
-112z"/>
<path d="M3320 2440 c0 -118 2 -140 15 -140 13 0 15 22 15 140 0 118 -2 140
-15 140 -13 0 -15 -22 -15 -140z"/>
<path d="M3410 2440 c0 -118 2 -140 15 -140 13 0 15 22 15 140 0 118 -2 140
-15 140 -13 0 -15 -22 -15 -140z"/>
<path d="M3990 2442 l0 -138 63 -2 c84 -2 102 15 102 98 0 79 -22 104 -85 97
l-45 -5 1 31 c1 41 -5 57 -22 57 -11 0 -14 -27 -14 -138z m131 -9 c12 -33 -5
-99 -29 -105 -38 -10 -59 8 -65 58 -6 57 -1 73 27 84 27 10 54 -5 67 -37z"/>
<path d="M6610 2536 l0 -44 -43 5 c-39 5 -46 2 -65 -22 -30 -38 -30 -112 -1
-149 19 -24 26 -26 82 -23 l62 3 3 137 c2 132 2 137 -18 137 -17 0 -20 -6 -20
-44z m-2 -133 c3 -54 0 -63 -18 -73 -28 -15 -54 -5 -68 26 -28 59 -1 117 51
112 32 -3 32 -3 35 -65z"/>
<path d="M3143 2553 c-3 -10 -26 -69 -50 -131 -23 -63 -43 -116 -43 -118 0 -2
7 -4 16 -4 8 0 22 16 30 35 15 36 15 36 72 33 54 -3 57 -4 66 -35 6 -23 15
-33 28 -33 23 0 24 -7 -36 150 -44 113 -68 144 -83 103z m45 -93 l21 -60 -46
0 c-39 0 -44 2 -38 18 4 9 14 36 22 60 8 23 16 42 18 42 2 0 12 -27 23 -60z"/>
<path d="M4730 2555 c0 -10 7 -15 18 -13 21 4 19 22 -2 26 -10 2 -16 -3 -16
-13z"/>
<path d="M5160 2556 c0 -8 4 -17 9 -20 11 -7 33 18 24 27 -12 12 -33 7 -33 -7z"/>
<path d="M5050 2525 c0 -18 -5 -25 -20 -25 -11 0 -20 -7 -20 -15 0 -8 9 -15
20 -15 17 0 19 -8 22 -82 l3 -83 33 -3 c35 -4 44 13 12 23 -17 6 -20 15 -20
75 0 59 2 68 20 73 11 3 20 10 20 16 0 6 -9 11 -20 11 -15 0 -20 7 -20 25 0
16 -6 25 -15 25 -9 0 -15 -9 -15 -25z"/>
<path d="M3607 2482 c-20 -22 -22 -55 -4 -70 6 -5 31 -16 54 -22 48 -15 63
-38 37 -57 -25 -18 -41 -16 -64 7 -11 11 -27 20 -35 20 -13 0 -14 -4 -4 -22
17 -32 28 -38 75 -38 45 0 74 22 74 58 0 23 -29 47 -72 57 -39 10 -52 29 -34
48 17 17 49 12 67 -9 13 -16 39 -19 39 -4 0 6 -9 19 -20 30 -26 26 -90 27
-113 2z"/>
<path d="M3790 2416 c0 -106 8 -116 91 -114 l59 2 0 98 c0 80 -3 98 -15 98
-12 0 -15 -15 -15 -75 0 -68 -2 -76 -24 -90 -19 -13 -29 -14 -45 -5 -19 9 -21
20 -21 90 0 64 -3 80 -15 80 -12 0 -15 -16 -15 -84z"/>
<path d="M4226 2484 c-20 -19 -21 -58 -2 -73 7 -6 31 -17 52 -24 64 -22 54
-77 -11 -61 -13 3 -26 12 -29 20 -6 16 -36 19 -36 4 0 -22 41 -50 74 -50 48 0
66 9 77 37 14 37 -5 61 -57 74 -50 13 -67 35 -40 54 20 15 55 8 63 -11 7 -18
33 -18 33 -1 0 41 -92 64 -124 31z"/>
<path d="M4432 2493 c-19 -7 -42 -58 -42 -93 0 -37 23 -85 45 -94 40 -15 83
-6 105 22 18 22 19 28 7 30 -9 2 -25 -6 -36 -17 -32 -32 -57 -26 -80 16 -18
35 -13 72 14 98 30 31 40 31 69 0 41 -44 62 -29 25 19 -16 20 -29 26 -57 25
-20 0 -43 -3 -50 -6z"/>
<path d="M4590 2398 c0 -91 1 -98 20 -98 18 0 20 7 20 73 0 75 9 97 42 97 10
0 18 6 18 14 0 10 -14 13 -50 13 l-50 -1 0 -98z"/>
<path d="M4730 2400 c0 -82 3 -100 15 -100 12 0 15 18 15 100 0 82 -3 100 -15
100 -12 0 -15 -18 -15 -100z"/>
<path d="M4820 2360 c0 -118 2 -140 15 -140 11 0 15 12 15 45 0 44 0 44 30 39
50 -10 80 3 96 43 19 43 10 107 -17 134 -21 21 -78 26 -97 7 -9 -9 -12 -9 -12
0 0 7 -7 12 -15 12 -13 0 -15 -22 -15 -140z m118 92 c19 -25 15 -89 -8 -112
-24 -24 -38 -25 -62 -3 -27 24 -25 107 3 127 23 16 47 12 67 -12z"/>
<path d="M5160 2400 c0 -82 3 -100 15 -100 12 0 15 18 15 100 0 82 -3 100 -15
100 -12 0 -15 -18 -15 -100z"/>
<path d="M5266 2469 c-56 -66 -19 -168 61 -169 45 0 62 9 78 41 27 52 10 137
-30 153 -41 16 -83 6 -109 -25z m91 -4 c48 -33 36 -123 -18 -140 -56 -18 -90
96 -40 136 28 23 31 23 58 4z"/>
<path d="M5467 2493 c-4 -3 -7 -48 -7 -100 0 -86 1 -93 20 -93 18 0 20 7 20
74 0 65 3 77 21 90 22 15 48 11 61 -8 4 -6 8 -44 8 -83 0 -58 3 -73 15 -73 24
0 21 161 -3 183 -21 19 -73 22 -89 6 -8 -8 -13 -8 -17 0 -8 12 -20 14 -29 4z"/>
<path d="M5687 2482 c-10 -10 -17 -28 -17 -40 0 -23 34 -52 61 -52 9 0 27 -8
39 -18 21 -17 21 -19 5 -35 -22 -22 -41 -21 -65 3 -21 21 -50 27 -50 10 0 -5
9 -19 20 -30 27 -27 100 -28 125 0 22 25 13 67 -18 79 -12 5 -37 15 -55 21
-37 14 -39 38 -4 51 17 6 27 4 42 -11 25 -25 52 -27 43 -2 -10 28 -34 42 -73
42 -23 0 -43 -7 -53 -18z"/>
<path d="M5950 2400 c0 -93 1 -100 20 -100 17 0 19 8 22 83 l3 82 36 3 c43 4
49 -8 49 -105 0 -49 3 -63 15 -63 12 0 15 15 15 73 0 80 9 97 51 97 34 0 39
-14 39 -101 0 -55 3 -69 15 -69 22 0 22 162 -1 184 -18 19 -67 21 -94 4 -15
-10 -22 -10 -32 0 -15 15 -70 16 -85 1 -8 -8 -13 -8 -17 0 -4 6 -14 11 -22 11
-11 0 -14 -20 -14 -100z"/>
<path d="M6300 2480 c-11 -11 -20 -24 -20 -30 0 -15 30 -12 36 4 13 35 84 23
84 -14 0 -16 -7 -20 -38 -20 -53 0 -82 -23 -82 -65 0 -44 19 -54 96 -53 l59 1
-3 81 c-3 55 -9 87 -20 99 -21 24 -86 23 -112 -3z m100 -104 c0 -31 -22 -48
-57 -44 -37 4 -45 44 -11 57 44 18 68 13 68 -13z"/>
<path d="M6733 2485 c-27 -19 -47 -78 -38 -113 3 -15 16 -37 27 -49 28 -30 97
-32 122 -4 23 25 4 37 -26 17 -30 -21 -44 -20 -68 4 -38 38 -27 50 45 50 65 0
65 0 65 29 0 43 -35 81 -74 81 -17 0 -41 -7 -53 -15z m87 -34 c15 -30 14 -31
-40 -31 -54 0 -55 1 -40 31 7 12 21 19 40 19 19 0 33 -7 40 -19z"/>
<path d="M7032 2493 c-21 -8 -42 -60 -42 -103 0 -55 34 -90 88 -90 42 0 79 21
68 39 -5 8 -13 7 -29 -4 -12 -8 -29 -15 -38 -15 -17 0 -59 44 -59 61 0 5 32 9
70 9 52 0 70 3 70 14 0 28 -23 77 -40 86 -20 10 -66 12 -88 3z m84 -45 c8 -27
3 -30 -52 -26 l-49 3 26 27 c30 30 64 29 75 -4z"/>
<path d="M7219 2479 c-15 -15 -18 -24 -10 -32 8 -8 17 -5 31 11 25 26 43 28
64 6 28 -27 20 -44 -22 -44 -53 0 -82 -23 -82 -65 0 -24 6 -38 19 -45 26 -14
74 -13 89 2 9 9 12 9 12 0 0 -7 8 -12 18 -12 15 0 17 9 14 83 -3 56 -9 88 -20
100 -22 25 -86 23 -113 -4z m101 -103 c0 -31 -22 -48 -57 -44 -37 4 -45 44
-11 57 44 18 68 13 68 -13z"/>
<path d="M7421 2473 c-19 -23 -20 -29 -7 -47 7 -12 33 -28 57 -36 32 -11 45
-22 47 -38 2 -15 -2 -22 -15 -24 -36 -3 -58 3 -66 17 -9 17 -37 20 -37 5 0
-24 41 -50 80 -50 39 0 80 26 80 51 0 28 -24 50 -66 60 -50 13 -67 35 -40 54
20 15 55 8 63 -11 7 -18 33 -18 33 -1 0 24 -36 47 -73 47 -27 0 -41 -7 -56
-27z"/>
<path d="M7616 2402 c29 -81 33 -103 25 -125 -7 -17 -18 -27 -31 -27 -11 0
-20 -7 -20 -15 0 -24 47 -18 65 8 8 13 30 68 49 123 19 54 37 107 41 117 5 13
2 17 -12 17 -16 0 -24 -13 -38 -62 -10 -35 -21 -67 -25 -71 -4 -4 -17 24 -30
62 -17 54 -27 71 -41 71 -17 0 -15 -9 17 -98z"/>
</g>
</svg>


    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>;
}
